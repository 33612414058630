window.angular
    .module('MyHippoProducer.Components')
    .directive('minMaxInput', function () {
        return {
            restrict: 'E',
            scope: {
                ngModel: '=',
                ngDisabled: '<',
                label: '@',
                step: '@',
            },
            templateUrl: 'components/min-max-input/min-max-input.html',
        };
    });
